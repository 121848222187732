// Variables
const $toggleSub = $('.js-toggle-sub');
const $toggleMenu = $('.js-toggle-menu');
const $scrollTo = $('.js-scroll-to');

// Functions
const toggleSub = function (e) {
  e.preventDefault();
  const $this = $(this);
  const $parent = $this.closest('li');

  $parent.toggleClass('is-open-sub');
};

const toggleMenu = function (e) {
  e.preventDefault();
  const $this = $(this);
  const $parent = $('html');

  $parent.toggleClass('is-open-menu');
};

const scrollToElement = function (event) {
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
    location.hostname == this.hostname
  ) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();

      $('html, body').animate(
        {
          scrollTop:
            target.offset().top - $('.container-sticky').innerHeight() - 50,
        },
        1000,
        function () {}
      );
    }
  }
};

// Bind events & init plugins
$(document).ready(function () {
  // Make check for mobile!
  $toggleSub.on('click', toggleSub);
  $toggleMenu.on('click', toggleMenu);

  // Select all links with hashes
  $scrollTo.click(scrollToElement);

  //initialize swiper when document ready
  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });

  // Cookies
  const popup = document.querySelector('.notification-popup');
  const agreeBtn = document.querySelector('.agree-btn');

  function closePopup() {
    popup.classList.remove('opened');
    localStorage.setItem('agreed', true);
  }

  function openPopup() {
    popup.classList.add('opened');
  }

  if (!localStorage.agreed) {
    setTimeout(openPopup, 1000);
  }

  agreeBtn.addEventListener('click', closePopup);

  // sticky header add class
  const header = document.querySelector('.header-nav');
  const whySection = document.getElementById('why');

  document.addEventListener('scroll', () => {
    if (
      document.body.scrollTop > whySection.offsetTop ||
      document.documentElement.scrollTop > whySection.offsetTop
    ) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  });

  var $this = $('.site-form');
  var validator = new FormValidator(
    'site-form',
    [
      {
        name: 'names',
        rules: 'required',
      },
      {
        name: 'email',
        rules: 'required|valid_email',
      },
      {
        name: 'phone',
        rules: 'required',
      },
      {
        name: 'location',
        rules: 'required',
      },
      {
        name: 'agree',
        rules: 'required',
      },
      {
        name: 'g-recaptcha-response',
        rules: 'required',
      },
    ],

    function (errors, event) {
      event.preventDefault();

      if (errors.length > 0) {
        this.printValidationErrors($this, errors);
        return;
      }

      $.ajax({
        url: $this.attr('action'),
        data: $this.serialize(),
        type: 'POST',
        dataType: 'json',
        success: function ($response) {
          if ($.isEmptyObject($response.errors)) {
            window.location.replace('http://promo.bramac.bg/promo-1/thank-you');
          }
        },
      });
    }
  );
});
